<template>
    <div>
        <v-card style="height: 100%; width: 100%" flat>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-card style="border-radius: 20px; background: var(--v-toolbar-lighten1) !important">
                            <v-card-text class="text-center">
                                        <v-card-subtitle class="mt-0 pt-0" style="font-size: 14px">Unprocessed Files</v-card-subtitle>
                                        <h2 style="font-size: 28px">{{ totals.unprocessedFileTotal }}</h2>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-card style="border-radius: 20px; background: var(--v-toolbar-lighten1) !important">
                            <v-card-text class="text-center">
                                        <v-card-subtitle class="mt-0 pt-0" style="font-size: 14px">Not Ready to Invoice</v-card-subtitle>
                                        <h2 style="font-size: 28px">{{ totals.notReadyToInvoiceTotal }}</h2>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-card style="border-radius: 20px; background: var(--v-toolbar-lighten1) !important">
                            <v-card-text class="text-center">
                                        <v-card-subtitle class="mt-0 pt-0" style="font-size: 14px">Documents</v-card-subtitle>
                                        <h2 style="font-size: 28px">{{ totals.documentationTotal }}</h2>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-card style="border-radius: 20px; background: var(--v-toolbar-lighten1) !important">
                            <v-card-text class="text-center">
                                        <v-card-subtitle class="mt-0 pt-0" style="font-size: 14px">Open Files</v-card-subtitle>
                                        <h2 style="font-size: 28px">{{ totals.openFilesTotal }}</h2>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            
        </v-card>        
    </div>
</template>
<script>
export default {
    props: ['totals']
};
</script>